
.section-countdown {
    padding-top: 50px;
    padding-bottom: 50px;
}


.competitionContent {
    padding-left: 20px;
    padding-right: 20px;
}

.section-full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 100px;
    left: 50%;
}

.section-title {
    padding-left: 20px;
    padding-right: 20px;
}

.section-about {
    padding-left: 20px;
    padding-right: 20px;
}

.section-margin {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.section-jury {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 200px;
    padding-bottom: 200px;
}

.section-award {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 100px;
}

.section-registrationProcess {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
}

.section-category {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 100px;
}

.section-competitionGuidelines {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
}


.downloadCentered {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%
}

.scaleBankDetailTable {
    width: max(25%, min(400px, 100%));
    height: auto;
}


