.container h1 {
    color: black;
    text-align: center;
}

details {
    background-color: #1096a4;
    color: #fff;
    font-size: 16px;
    margin:10px;
}

summary {
    padding: .5em 1.3rem;
    list-style: none;
    display: flex;
    justify-content: space-between;
    transition: height 1s ease;
}

    summary:after {
        content: "\002B";
    }

details[open] summary {
    border-bottom: 1px solid #fff;
    margin-bottom: .5em;
}

    details[open] summary:after {
        content: "\00D7";
    }

details[open] div {
    padding: .5em 1em;
    background-color: #96f2fa;
    color:black;
}
