.margin-leftText {
    margin: 30px;
}
.margin-centerBottomText {
    padding-bottom: 10px;
    text-align: center;
}

.margin-countdown {
    text-align: center;
}
.margin-centerText {
    margin: 30px;
    text-align: center;
}
.margin-categoryComparison {
    margin: 10px;
    text-align: center;
}
.margin-centerWithWhiteBackground {
    margin: 30px;
    text-align: center;
    background-color:white;
    padding:10px;
    border-radius:20px;
}
.blink {
    animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

