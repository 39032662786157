.logo {
    width: 100px;
    height: auto;
}

img.scaleImage {
    max-width: 100%;
    min-width: 50%;
    height: auto;
}

img.scaleCategory {
    max-width: 80%;
    min-width: 80%;
    height: auto;
}

img.scaleCategoryComparison{
    max-width: 100%;
    min-width: 100%;
    height: auto;
}

img.scaleRegistrationPoster {
    max-width: 100%;
    min-width: 100%;
    height: auto;
}

img.scaleCollabLogo {
    max-width: 60%;
    min-width: 40%;
    height: auto;
}
img.scaleCollabLogoWithText {
    max-width: 90%;
    min-width: 70%;
    height: auto;
}

img.scaleSponsorLogo {
    max-width: 40%;
    min-width: 40%;
    height: auto;
}

img.scaleQR {
    max-width: 50%;
    min-width: 30%;
    height: auto;
}

img.scaleAboutFigure {
    max-width: 50%;
    min-width: 30%;
    height: auto;
}
.customBackgroundImage {
    text-align: center;
    background-image: url(".././image/registration_closed_poster.jpg");
    background-size: cover;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom:35%;
    padding-top:18%;
}
.downloadImage {
    background-image: url(".././image/academic-building.png");
    background-size: cover;
    padding: 20%;
    margin-right: 20%;
    margin-left: 20%;
}

