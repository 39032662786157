.margin-footer {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer {
    position: relative;
    left: 0;
    bottom: 0;
    right: 0;
}
