td {
    font-size: 16px;
}

#tableWithHeader {
    /*   // font-family: Arial, Helvetica, sans-serif;*/
    border-collapse: collapse;
    width: 100%;
}

    #tableWithHeader td, #tableWithHeader th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    #tableWithHeader tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    #tableWithHeader tr:hover {
        background-color: #ddd;
    }

    #tableWithHeader th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #9bf2fa;
        color: black;
        font-size: 16px;
    }

#tableWithoutHeader {
    /*   // font-family: Arial, Helvetica, sans-serif;*/
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}

    #tableWithoutHeader td, #tableWithoutHeader th {
        border: 1px solid black;
        padding: 8px;
    }

    #tableWithoutHeader tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    #tableWithoutHeader tr:hover {
        background-color: #ddd
    }

.centerTable {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

