/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

 a.hyperlinkNone {
        text-decoration: none;
        color: black;
        line-height:25px;
    }

p {
    font-size:18px;
 }

li {
    font-size: 18px;
}
code {
  color: #E01A76;
}

.wrapLink {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 40%;
    font-size: 16px;
}

